import './App.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import AuthLayout from './layouts/AuthLayout'; // Import AuthLayout

import { AuthContextProvider } from './context/AuthContext';
import { ToastProvider } from './components/providers/ToastProvider';

function App() {
	const router = createBrowserRouter([
		{
			path: '/',
			element: (
				<Route>
					<AuthLayout />
				</Route>
			),
		},
		{
			path: 'PersonalMastery',
			element: (
				<Route>
					<AuthLayout />
				</Route>
			),
		}
	]);

	return (
		<AuthContextProvider>
			<ToastProvider>
				<RouterProvider router={router} />
			</ToastProvider>
		</AuthContextProvider>
	);
}

export default App;

const Route = ({ children }) => {
	return children;
};
